// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.standard-form-labeled-checkbox-outer-wrapper {
  border: var(--border-pri);
  padding: 0.5em;
  flex: 1;
}

div.standard-form-labeled-checkbox-outer-wrapper:hover, div.standard-form-labeled-checkbox-outer-wrapper:focus {
  box-shadow: var(--box-shadow-normal);
  color: var(--clr-accent-dark);
}

div.standard-form-labeled-checkbox-outer-wrapper:active:hover {
  scale: 0.95;
  box-shadow: var(--box-shadow-small);
}

div.standard-form-labeled-checkbox-wrapper {
  display: flex;
  flex-direction: row;  
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/standardFormLabeledCheckbox/StandardFormLabeledCheckbox.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,OAAO;AACT;;AAEA;EACE,oCAAoC;EACpC,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,QAAQ;EACR,WAAW;AACb","sourcesContent":["div.standard-form-labeled-checkbox-outer-wrapper {\n  border: var(--border-pri);\n  padding: 0.5em;\n  flex: 1;\n}\n\ndiv.standard-form-labeled-checkbox-outer-wrapper:hover, div.standard-form-labeled-checkbox-outer-wrapper:focus {\n  box-shadow: var(--box-shadow-normal);\n  color: var(--clr-accent-dark);\n}\n\ndiv.standard-form-labeled-checkbox-outer-wrapper:active:hover {\n  scale: 0.95;\n  box-shadow: var(--box-shadow-small);\n}\n\ndiv.standard-form-labeled-checkbox-wrapper {\n  display: flex;\n  flex-direction: row;  \n  justify-content: space-between;\n  align-items: center;\n  gap: 1em;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
