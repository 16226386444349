// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form.calculator-wrapper {
  border: var(--border-nav);
  box-shadow: var(--box-shadow-normal);
  background-color: var(--clr-white);
}

span.calculator-total {
  font-weight: 700;
  font-size: 1.4em;
  display: inline-block;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/contexts/calculator/Calculator.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,oCAAoC;EACpC,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;EACrB,WAAW;AACb","sourcesContent":["form.calculator-wrapper {\n  border: var(--border-nav);\n  box-shadow: var(--box-shadow-normal);\n  background-color: var(--clr-white);\n}\n\nspan.calculator-total {\n  font-weight: 700;\n  font-size: 1.4em;\n  display: inline-block;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
