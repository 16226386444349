// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form.update-settings-form-wrapper {
  border: var(--border-pri);
  border-width: 2px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/settings/form/UpdateSettingsForm.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,WAAW;AACb","sourcesContent":["form.update-settings-form-wrapper {\n  border: var(--border-pri);\n  border-width: 2px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
