// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.browse-page-controls-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;

  margin: 0 auto;

  width: fit-content;

  padding: 0.5em;
}

div.browse-view-mode-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5em;
  width: 100%;
}

div.browse-view-mode-buttons>button {
  flex: 1;
  min-width: fit-content;
  text-align: center;
}

div.browse-view-mode-buttons>button.active {
  background-color: var(--clr-accent-dark);
  color: var(--clr-white);
  pointer-events: none;
}

.browse-date-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 0.5em;
}

.browse-date-controls button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/browse/controls/BrowsePageControls.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;;EAEV,cAAc;;EAEd,kBAAkB;;EAElB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,OAAO;EACP,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,wCAAwC;EACxC,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":["div.browse-page-controls-wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 0.5em;\n\n  margin: 0 auto;\n\n  width: fit-content;\n\n  padding: 0.5em;\n}\n\ndiv.browse-view-mode-buttons {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  gap: 0.5em;\n  width: 100%;\n}\n\ndiv.browse-view-mode-buttons>button {\n  flex: 1;\n  min-width: fit-content;\n  text-align: center;\n}\n\ndiv.browse-view-mode-buttons>button.active {\n  background-color: var(--clr-accent-dark);\n  color: var(--clr-white);\n  pointer-events: none;\n}\n\n.browse-date-controls {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: stretch;\n  gap: 0.5em;\n}\n\n.browse-date-controls button {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
