// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.create-page-alternate-upload-links {
  display: flex;
  flex-direction: row;
  gap: 2em;
}

div.create-page-alternate-upload-links a {
  color: var(--clr-accent-light);
  text-decoration: underline;
}

div.create-page-alternate-upload-links a:hover, div.create-page-alternate-upload-links a:focus {
  color: var(--clr-accent-dark)
}`, "",{"version":3,"sources":["webpack://./src/components/pages/create/CreatePage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,8BAA8B;EAC9B,0BAA0B;AAC5B;;AAEA;EACE;AACF","sourcesContent":["div.create-page-alternate-upload-links {\n  display: flex;\n  flex-direction: row;\n  gap: 2em;\n}\n\ndiv.create-page-alternate-upload-links a {\n  color: var(--clr-accent-light);\n  text-decoration: underline;\n}\n\ndiv.create-page-alternate-upload-links a:hover, div.create-page-alternate-upload-links a:focus {\n  color: var(--clr-accent-dark)\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
