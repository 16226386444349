// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.batch-transaction-input {
  row-gap: 1em;
  border: var(--border-pri);
  border-color: var(--clr-accent-light);
  padding: 1em;
  justify-content: flex-start;
}

div.batch-transaction-input>* {
  min-width: max-content;
  max-width: 200px;
}
  
h3.batch-transaction-category-earning {
  color: var(--clr-earning);
}

h3.batch-transaction-category-expense {
  color: var(--clr-expense);
}`, "",{"version":3,"sources":["webpack://./src/components/pages/batchReport/form/batchTransactionInput/BatchTransactionInput.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,qCAAqC;EACrC,YAAY;EACZ,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["div.batch-transaction-input {\n  row-gap: 1em;\n  border: var(--border-pri);\n  border-color: var(--clr-accent-light);\n  padding: 1em;\n  justify-content: flex-start;\n}\n\ndiv.batch-transaction-input>* {\n  min-width: max-content;\n  max-width: 200px;\n}\n  \nh3.batch-transaction-category-earning {\n  color: var(--clr-earning);\n}\n\nh3.batch-transaction-category-expense {\n  color: var(--clr-expense);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
