// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.login-expired-message {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: var(--clr-error);
}

span.local-login-invitation {
  display: block;
  margin: 0 auto;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/login/LoginPage.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,kBAAkB;AACpB","sourcesContent":["span.login-expired-message {\n  display: inline-block;\n  width: 100%;\n  text-align: center;\n  color: var(--clr-error);\n}\n\nspan.local-login-invitation {\n  display: block;\n  margin: 0 auto;\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
