// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.empty-display-card-wrapper {
  background: var(--bg-gradient);
  background-attachment: fixed;
  border: var(--border-pri);
  box-shadow: inset var(--box-shadow-normal);
}

@media (max-width: 1320px) {
  div.empty-display-card-outer-wrapper {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/browse/display/emptyDisplayCards/EmptyDisplayCards.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,4BAA4B;EAC5B,yBAAyB;EACzB,0CAA0C;AAC5C;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["div.empty-display-card-wrapper {\n  background: var(--bg-gradient);\n  background-attachment: fixed;\n  border: var(--border-pri);\n  box-shadow: inset var(--box-shadow-normal);\n}\n\n@media (max-width: 1320px) {\n  div.empty-display-card-outer-wrapper {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
