// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-bar-chart-controls-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  width: 80%;
  min-width: fit-content;
  text-wrap: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/detail/display/detailBarChart/controls/DetailBarChartControls.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,UAAU;EACV,UAAU;EACV,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":[".detail-bar-chart-controls-wrapper {\n  display: flex;\n  flex-direction: row;\n  gap: 0.5em;\n  width: 80%;\n  min-width: fit-content;\n  text-wrap: nowrap;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
