// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer.footer-warning-wrapper {
  position:fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--clr-white);
  text-align: center;
  background-color: var(--clr-translucent-medium);
  padding: 10px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/footerWarning/FooterWarning.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,SAAS;EACT,OAAO;EACP,QAAQ;EACR,uBAAuB;EACvB,kBAAkB;EAClB,+CAA+C;EAC/C,aAAa;EACb,gBAAgB;AAClB","sourcesContent":["footer.footer-warning-wrapper {\n  position:fixed;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  color: var(--clr-white);\n  text-align: center;\n  background-color: var(--clr-translucent-medium);\n  padding: 10px;\n  font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
