// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-display-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:1em;
}

.detail-control-button {
  flex: 1;
  text-align: center;
}

.detail-control-button.active {
  background-color: var(--clr-accent-dark);
  color: var(--clr-white);
  pointer-events: none;
}

.detail-chart-wrapper {
  padding: 1em;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/detail/display/DetailDisplay.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,OAAO;AACT;;AAEA;EACE,OAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,wCAAwC;EACxC,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".detail-display-wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap:1em;\n}\n\n.detail-control-button {\n  flex: 1;\n  text-align: center;\n}\n\n.detail-control-button.active {\n  background-color: var(--clr-accent-dark);\n  color: var(--clr-white);\n  pointer-events: none;\n}\n\n.detail-chart-wrapper {\n  padding: 1em;\n  width: 100%;\n  height: 400px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
