// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form.create-report-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  border: var(--border-pri);
  min-width: fit-content;
  width: min(980px, 100%);
}

form.create-report-form-wrapper h2 {
  text-align: center;
}

form.create-report-form-wrapper h3 {
  /* color: var(--clr-accent-dark); */
}

form.create-report-form-wrapper>div.form-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 0.5em;
}

div.create-report-form-subsection {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

div.transaction-input-wrapper {
  border: var(--border-pri);
  border-color: var(--clr-accent-light);
  padding: 0.5em;

  display: flex;
  flex-direction: column;
  gap: 1em;
  
  width: max(100%, max-content);
}

@media (max-width: 980px) {
  form.create-report-form-wrapper>div.form-body {
    flex-direction: column;
    align-items: stretch;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/create/form/CreateReportForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,yBAAyB;EACzB,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,uBAAuB;EACvB,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,qCAAqC;EACrC,cAAc;;EAEd,aAAa;EACb,sBAAsB;EACtB,QAAQ;;EAER,6BAA6B;AAC/B;;AAEA;EACE;IACE,sBAAsB;IACtB,oBAAoB;EACtB;AACF","sourcesContent":["form.create-report-form-wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 1em;\n  border: var(--border-pri);\n  min-width: fit-content;\n  width: min(980px, 100%);\n}\n\nform.create-report-form-wrapper h2 {\n  text-align: center;\n}\n\nform.create-report-form-wrapper h3 {\n  /* color: var(--clr-accent-dark); */\n}\n\nform.create-report-form-wrapper>div.form-body {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: flex-start;\n  width: 100%;\n  gap: 0.5em;\n}\n\ndiv.create-report-form-subsection {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5em;\n}\n\ndiv.transaction-input-wrapper {\n  border: var(--border-pri);\n  border-color: var(--clr-accent-light);\n  padding: 0.5em;\n\n  display: flex;\n  flex-direction: column;\n  gap: 1em;\n  \n  width: max(100%, max-content);\n}\n\n@media (max-width: 980px) {\n  form.create-report-form-wrapper>div.form-body {\n    flex-direction: column;\n    align-items: stretch;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
