// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.transaction-category-settings-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  width: 100%;
}

div.transaction-categories-section-subsection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
  border: var(--border-pri);
  border-color: var(--clr-accent-light);
  padding: 0.5em;
}

div.settings-expense-category {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 0.2em;
  padding: 0.5em 0em;
}

div.settings-expense-category-subcategories ul {
  padding: 0.5em 0em 0.5em 1em;
  list-style-type: square;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
}

li.settings-expense-subcategory>div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 0.5em;
  width: 100%;
}

li.settings-expense-subcategory span {
  word-break: break-all;
}

li.settings-expense-subcategory button {
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/settings/form/transactionCategories/TransactionCategoriesSection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;EACV,yBAAyB;EACzB,qCAAqC;EACrC,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,wBAAwB;EACxB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,oBAAoB;EACpB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["div.transaction-category-settings-row {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  gap: 0.5em;\n  width: 100%;\n}\n\ndiv.transaction-categories-section-subsection {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 0.2em;\n  border: var(--border-pri);\n  border-color: var(--clr-accent-light);\n  padding: 0.5em;\n}\n\ndiv.settings-expense-category {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: stretch;\n  gap: 0.2em;\n  padding: 0.5em 0em;\n}\n\ndiv.settings-expense-category-subcategories ul {\n  padding: 0.5em 0em 0.5em 1em;\n  list-style-type: square;\n  display: flex;\n  flex-direction: column;\n  gap: 1em;\n  width: 100%;\n}\n\nli.settings-expense-subcategory>div {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: stretch;\n  gap: 0.5em;\n  width: 100%;\n}\n\nli.settings-expense-subcategory span {\n  word-break: break-all;\n}\n\nli.settings-expense-subcategory button {\n  flex-shrink: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
