// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.result-display-wrapper {
  width: fit-content;
  margin: auto;
}

span.result-display-title {
  font-weight: 600;
  font-size: 1.2em;
}

ul.result-display-errors-list {
  list-style-type: disc;
  list-style-position:inside;
}`, "",{"version":3,"sources":["webpack://./src/components/resultDisplay/ResultDisplay.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,0BAA0B;AAC5B","sourcesContent":["div.result-display-wrapper {\n  width: fit-content;\n  margin: auto;\n}\n\nspan.result-display-title {\n  font-weight: 600;\n  font-size: 1.2em;\n}\n\nul.result-display-errors-list {\n  list-style-type: disc;\n  list-style-position:inside;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
