// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.browse-page-display-wrapper {
  width: 100%;
}

div.browse-page-display-reports-wrapper {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: 0 auto;
  width: fit-content;
}

div.browse-page-display-reports-wrapper.single-column {
  grid-template-columns: 1fr;
}

.browse-page-display-card-outer-wrapper {
  padding: 0.4em 0.2em;
  display: flex;
  flex-direction: column;
}

div.browse-page-display-card-outer-wrapper>* {
  flex: 1;
}

div.days-page-display-days-of-the-week-cell {
  padding: 0.5em;
  text-align: center;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 1.5em;
  background: var(--bg-gradient);
  background-attachment: fixed;
  box-shadow: inset var(--box-shadow-normal);
  color: var(--clr-white);
  border: var(--border-pri);
}

@media (max-width: 1320px) {
  
  div.browse-page-display-reports-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .browse-page-display-card-outer-wrapper.days-of-the-week {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/browse/display/BrowsePageDisplay.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,oBAAoB;EACpB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,0BAA0B;EAC1B,gBAAgB;EAChB,gBAAgB;EAChB,8BAA8B;EAC9B,4BAA4B;EAC5B,0CAA0C;EAC1C,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;;EAEE;IACE,aAAa;IACb,sBAAsB;IACtB,WAAW;EACb;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":["div.browse-page-display-wrapper {\n  width: 100%;\n}\n\ndiv.browse-page-display-reports-wrapper {\n  display: grid;\n  grid-template-columns: repeat(7, 1fr);\n  margin: 0 auto;\n  width: fit-content;\n}\n\ndiv.browse-page-display-reports-wrapper.single-column {\n  grid-template-columns: 1fr;\n}\n\n.browse-page-display-card-outer-wrapper {\n  padding: 0.4em 0.2em;\n  display: flex;\n  flex-direction: column;\n}\n\ndiv.browse-page-display-card-outer-wrapper>* {\n  flex: 1;\n}\n\ndiv.days-page-display-days-of-the-week-cell {\n  padding: 0.5em;\n  text-align: center;\n  text-transform: capitalize;\n  font-weight: 700;\n  font-size: 1.5em;\n  background: var(--bg-gradient);\n  background-attachment: fixed;\n  box-shadow: inset var(--box-shadow-normal);\n  color: var(--clr-white);\n  border: var(--border-pri);\n}\n\n@media (max-width: 1320px) {\n  \n  div.browse-page-display-reports-wrapper {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n  }\n\n  .browse-page-display-card-outer-wrapper.days-of-the-week {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
