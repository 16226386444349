// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.browse-summary-wrapper {
  margin: 0 auto;
  width: fit-content;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 10px;

  padding: 0.5em;
}

div.browse-summary-header {
  font-size: 1.5em;
  font-weight: 700;
}

div.browse-summary-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;

  width: 100%;
}

table.browse-summary-table {
  width: 100%;
}

table.browse-summary-table tr {
  border-bottom: var(--border-pri);
}

table.browse-summary-table tbody tr:last-child {
  border: none;
}

table.browse-summary-table tr td {
  padding: 0.2em;
}

tr.browse-summary-table-headers {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 1.2em;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/browse/summary/BrowseSummary.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;;EAElB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,oBAAoB;EACpB,SAAS;;EAET,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,QAAQ;;EAER,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":["div.browse-summary-wrapper {\n  margin: 0 auto;\n  width: fit-content;\n  \n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: stretch;\n  gap: 10px;\n\n  padding: 0.5em;\n}\n\ndiv.browse-summary-header {\n  font-size: 1.5em;\n  font-weight: 700;\n}\n\ndiv.browse-summary-row {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 1em;\n\n  width: 100%;\n}\n\ntable.browse-summary-table {\n  width: 100%;\n}\n\ntable.browse-summary-table tr {\n  border-bottom: var(--border-pri);\n}\n\ntable.browse-summary-table tbody tr:last-child {\n  border: none;\n}\n\ntable.browse-summary-table tr td {\n  padding: 0.2em;\n}\n\ntr.browse-summary-table-headers {\n  text-transform: capitalize;\n  font-weight: 700;\n  font-size: 1.2em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
