// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.general-settings-section-inner-wrapper {
  width: fit-content;

  display: flex;
  flex-direction: column;
  gap: 0.5em;

  width: 100%;
}

section.general-settings-section-wrapper div.standard-form-row {
  justify-content: space-between;
  width: 100%;
  border: var(--border-pri);
  border-color: var(--clr-accent-light);
  padding: 0.5em;
  align-items: center;
}

div.default-view-mode-section button.active {
  background-color: var(--clr-accent-dark);
  color: var(--clr-white);
  pointer-events: none;
}

div.default-view-mode-buttons {
  display: flex;
  flex-direction: row;
  gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/settings/form/generalSettings/GeneralSettingsSection.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;;EAElB,aAAa;EACb,sBAAsB;EACtB,UAAU;;EAEV,WAAW;AACb;;AAEA;EACE,8BAA8B;EAC9B,WAAW;EACX,yBAAyB;EACzB,qCAAqC;EACrC,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,wCAAwC;EACxC,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV","sourcesContent":["div.general-settings-section-inner-wrapper {\n  width: fit-content;\n\n  display: flex;\n  flex-direction: column;\n  gap: 0.5em;\n\n  width: 100%;\n}\n\nsection.general-settings-section-wrapper div.standard-form-row {\n  justify-content: space-between;\n  width: 100%;\n  border: var(--border-pri);\n  border-color: var(--clr-accent-light);\n  padding: 0.5em;\n  align-items: center;\n}\n\ndiv.default-view-mode-section button.active {\n  background-color: var(--clr-accent-dark);\n  color: var(--clr-white);\n  pointer-events: none;\n}\n\ndiv.default-view-mode-buttons {\n  display: flex;\n  flex-direction: row;\n  gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
