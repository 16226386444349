// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.settings-page-inner-wrapper {
  width: fit-content;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 5em;
}

.settings-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 1em;

  margin: 0 auto;
  padding: 1em;

  border: var(--border-pri);
  border-width: 2px;

  width: 100%;
}

.settings-sub-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 0.5em;

  width: 100%;
  padding: 1em 0.5em;
  border: var(--border-pri);
}

section.settings-section h2 {
  font-size: 1.4em;
  font-weight: 600;
}

@media (max-width: 980px) {
  div.settings-page-inner-wrapper {
    max-width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/settings/SettingsPage.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;;EAEd,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;;EAEnB,QAAQ;;EAER,cAAc;EACd,YAAY;;EAEZ,yBAAyB;EACzB,iBAAiB;;EAEjB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;;EAEnB,UAAU;;EAEV,WAAW;EACX,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["div.settings-page-inner-wrapper {\n  width: fit-content;\n  margin: 0 auto;\n\n  display: flex;\n  flex-direction: column;\n  gap: 5em;\n}\n\n.settings-section {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  gap: 1em;\n\n  margin: 0 auto;\n  padding: 1em;\n\n  border: var(--border-pri);\n  border-width: 2px;\n\n  width: 100%;\n}\n\n.settings-sub-section {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  gap: 0.5em;\n\n  width: 100%;\n  padding: 1em 0.5em;\n  border: var(--border-pri);\n}\n\nsection.settings-section h2 {\n  font-size: 1.4em;\n  font-weight: 600;\n}\n\n@media (max-width: 980px) {\n  div.settings-page-inner-wrapper {\n    max-width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
