// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --clr-white: #F6F7EB;
  --clr-black: #383F42;

  --clr-accent-light: #89B0AE;
  --clr-accent-dark: #1F62FF;

  --clr-bg-light: #879197;
  --clr-bg-dark: #42494D;

  --clr-earning: #5C8DFF;
  --clr-expense: #F58B51;

  --clr-translucent-light: rgba(0, 0, 0, 0.1);
  --clr-translucent-medium: rgba(0, 0, 0, 0.4);

  --clr-error: red;
  --clr-success: green;

  --border-pri: 1px solid var(--clr-black);
  --border-loading-spinner: solid 5px var(--clr-white);
  --border-nav: 2px solid var(--clr-accent-light);

  --box-shadow-large: 10px 10px 10px var(--clr-black);
  --box-shadow-normal: 2px 2px 2px var(--clr-black);
  --box-shadow-small: 1px 1px 1px var(--clr-black);

  --bg-gradient: linear-gradient(127deg, var(--clr-bg-dark) 0%, var(--clr-bg-light) 100%);
}`, "",{"version":3,"sources":["webpack://./src/styles/vars.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;;EAEpB,2BAA2B;EAC3B,0BAA0B;;EAE1B,uBAAuB;EACvB,sBAAsB;;EAEtB,sBAAsB;EACtB,sBAAsB;;EAEtB,2CAA2C;EAC3C,4CAA4C;;EAE5C,gBAAgB;EAChB,oBAAoB;;EAEpB,wCAAwC;EACxC,oDAAoD;EACpD,+CAA+C;;EAE/C,mDAAmD;EACnD,iDAAiD;EACjD,gDAAgD;;EAEhD,uFAAuF;AACzF","sourcesContent":[":root {\n  --clr-white: #F6F7EB;\n  --clr-black: #383F42;\n\n  --clr-accent-light: #89B0AE;\n  --clr-accent-dark: #1F62FF;\n\n  --clr-bg-light: #879197;\n  --clr-bg-dark: #42494D;\n\n  --clr-earning: #5C8DFF;\n  --clr-expense: #F58B51;\n\n  --clr-translucent-light: rgba(0, 0, 0, 0.1);\n  --clr-translucent-medium: rgba(0, 0, 0, 0.4);\n\n  --clr-error: red;\n  --clr-success: green;\n\n  --border-pri: 1px solid var(--clr-black);\n  --border-loading-spinner: solid 5px var(--clr-white);\n  --border-nav: 2px solid var(--clr-accent-light);\n\n  --box-shadow-large: 10px 10px 10px var(--clr-black);\n  --box-shadow-normal: 2px 2px 2px var(--clr-black);\n  --box-shadow-small: 1px 1px 1px var(--clr-black);\n\n  --bg-gradient: linear-gradient(127deg, var(--clr-bg-dark) 0%, var(--clr-bg-light) 100%);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
