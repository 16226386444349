// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.standard-form-labeled-input-outer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  /* padding: 0.8em 0; */
  flex: 1;
}

div.standard-form-labeled-input-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
}

div.standard-form-labeled-input-wrapper>label {
  position: absolute;
  top: -0.7em;
  left: 0.5em;
  background-color: var(--clr-white);
  padding: 0 0.2em;
  text-transform: capitalize;
}

div.standard-form-labeled-input-wrapper>input {
  border: var(--border-pri);
  padding: 0.5em;
  flex: 1;
}

div.standard-form-labeled-input-wrapper>input:hover, div.standard-form-labeled-input-wrapper>input:focus {
  color: var(--clr-accent-dark);
  box-shadow: var(--box-shadow-normal);
}

span.standard-form-labeleed-input-error-message {
  color: var(--clr-error);
  font-size: 0.7em;
  min-height: 2.3em;
  /* padding: 0.5em 0; */
}`, "",{"version":3,"sources":["webpack://./src/components/forms/standardFormLabeledInput/StandardFormLabeledInput.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;;EAEZ,sBAAsB;EACtB,OAAO;AACT;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,kCAAkC;EAClC,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,OAAO;AACT;;AAEA;EACE,6BAA6B;EAC7B,oCAAoC;AACtC;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":["div.standard-form-labeled-input-outer-wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  height: 100%;\n\n  /* padding: 0.8em 0; */\n  flex: 1;\n}\n\ndiv.standard-form-labeled-input-wrapper {\n  position: relative;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n}\n\ndiv.standard-form-labeled-input-wrapper>label {\n  position: absolute;\n  top: -0.7em;\n  left: 0.5em;\n  background-color: var(--clr-white);\n  padding: 0 0.2em;\n  text-transform: capitalize;\n}\n\ndiv.standard-form-labeled-input-wrapper>input {\n  border: var(--border-pri);\n  padding: 0.5em;\n  flex: 1;\n}\n\ndiv.standard-form-labeled-input-wrapper>input:hover, div.standard-form-labeled-input-wrapper>input:focus {\n  color: var(--clr-accent-dark);\n  box-shadow: var(--box-shadow-normal);\n}\n\nspan.standard-form-labeleed-input-error-message {\n  color: var(--clr-error);\n  font-size: 0.7em;\n  min-height: 2.3em;\n  /* padding: 0.5em 0; */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
