// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stock-breakdown-wrapper {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  max-width: 80%;
  padding: 1rem 2rem;
  border: var(--border-pri);
}

.stock-breakdown-wrapper table.browse-summary-table tr {
  border: var(--border-pri);
}

.stock-breakdown-wrapper table.browse-summary-table tr td {
  padding: 0.2em 1em;
  border-bottom: var(--border-pri);
  border-top: var(--border-pri);
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/detail/display/stockBreakdown/StockBreakdown.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,cAAc;EACd,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,gCAAgC;EAChC,6BAA6B;AAC/B","sourcesContent":[".stock-breakdown-wrapper {\n  width: fit-content;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  gap: 3rem;\n  max-width: 80%;\n  padding: 1rem 2rem;\n  border: var(--border-pri);\n}\n\n.stock-breakdown-wrapper table.browse-summary-table tr {\n  border: var(--border-pri);\n}\n\n.stock-breakdown-wrapper table.browse-summary-table tr td {\n  padding: 0.2em 1em;\n  border-bottom: var(--border-pri);\n  border-top: var(--border-pri);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
