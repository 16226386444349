// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  color: var(--clr-black);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: var(--bg-gradient);
}

body {
  min-height: 100vh;
}

main {
  overflow-x: hidden;
  background-color: var(--clr-white);
  max-width: min(1400px, calc(100vw - 40px));
  margin: 30px auto;
  padding: 20px;

  min-height: 400px;

  box-shadow: var(--box-shadow-normal);

  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

h1,
h2,
h3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  text-align: center;
  font-size: 1.7em;
  font-weight: 700;
  text-transform: capitalize;
}

h2 {
  text-transform: capitalize;
  font-size: 1.4em;
  font-weight: 700;
}

h3 {
  text-transform: capitalize;
  font-size: 1.2em;
  font-weight: 700;
}

span.center {
  display: block;
  text-align: center;
}

.error {
  color: var(--clr-error);
}

.success {
  color: var(--clr-success);
}

.heavy {
  font-weight: 700;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.full-width {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB;wEACsE;EACtE,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,0CAA0C;EAC1C,iBAAiB;EACjB,aAAa;;EAEb,iBAAiB;;EAEjB,oCAAoC;;EAEpC,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;AACrB;;AAEA;;;EAGE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb","sourcesContent":["html {\n  color: var(--clr-black);\n  font-family: system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto,\n    Oxygen, Ubuntu, Cantarell, \"Open Sans\", \"Helvetica Neue\", sans-serif;\n  background: var(--bg-gradient);\n}\n\nbody {\n  min-height: 100vh;\n}\n\nmain {\n  overflow-x: hidden;\n  background-color: var(--clr-white);\n  max-width: min(1400px, calc(100vw - 40px));\n  margin: 30px auto;\n  padding: 20px;\n\n  min-height: 400px;\n\n  box-shadow: var(--box-shadow-normal);\n\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  align-items: center;\n}\n\nh1,\nh2,\nh3 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\nh1 {\n  text-align: center;\n  font-size: 1.7em;\n  font-weight: 700;\n  text-transform: capitalize;\n}\n\nh2 {\n  text-transform: capitalize;\n  font-size: 1.4em;\n  font-weight: 700;\n}\n\nh3 {\n  text-transform: capitalize;\n  font-size: 1.2em;\n  font-weight: 700;\n}\n\nspan.center {\n  display: block;\n  text-align: center;\n}\n\n.error {\n  color: var(--clr-error);\n}\n\n.success {\n  color: var(--clr-success);\n}\n\n.heavy {\n  font-weight: 700;\n}\n\n.left-align {\n  text-align: left;\n}\n\n.right-align {\n  text-align: right;\n}\n\n.full-width {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
