// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-bar-chart-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  width: min(100%, 800px);
  margin: 0 auto;
  padding: 1em;
  border: var(--border-pri);
}`, "",{"version":3,"sources":["webpack://./src/components/pages/detail/display/detailBarChart/DetailBarChart.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,uBAAuB;EACvB,cAAc;EACd,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":[".detail-bar-chart-wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 1em;\n  width: min(100%, 800px);\n  margin: 0 auto;\n  padding: 1em;\n  border: var(--border-pri);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
