// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes loading-spinner-spin {
  from {
    rotate: 0deg
  }
  to {
    rotate: 360deg
  }
}

div.loading-spinner-wrapper {
  margin: auto;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: var(--border-loading-spinner);
  border-width: 5px 0px 0px 0px;
  animation-name: loading-spinner-spin;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}`, "",{"version":3,"sources":["webpack://./src/app/contexts/loadingSpinner/LoadingSpinner.css"],"names":[],"mappings":"AAAA;EACE;IACE;EACF;EACA;IACE;EACF;AACF;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,qCAAqC;EACrC,6BAA6B;EAC7B,oCAAoC;EACpC,sBAAsB;EACtB,iCAAiC;EACjC,mCAAmC;AACrC","sourcesContent":["@keyframes loading-spinner-spin {\n  from {\n    rotate: 0deg\n  }\n  to {\n    rotate: 360deg\n  }\n}\n\ndiv.loading-spinner-wrapper {\n  margin: auto;\n  width: 200px;\n  height: 200px;\n  border-radius: 50%;\n  border: var(--border-loading-spinner);\n  border-width: 5px 0px 0px 0px;\n  animation-name: loading-spinner-spin;\n  animation-duration: 2s;\n  animation-timing-function: linear;\n  animation-iteration-count: infinite;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
